// import React, { useState } from 'react';
// import axios from 'axios';

// const Testing = () => {
//   const [file, setFile] = useState(null);
//   const [formData, setFormData] = useState({
//     productname: 'venky',
//     category: '',
//     type: '',
//     price: '',
//     status:'',
//     description:'',
//     image:'',
//     addon:[]

//     // Add more fields as needed
//   });

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//   };

//   const handleFileUpload = async () => {
//     try {
//       const formDataObj = new FormData();
//       formDataObj.append('file', file);
//       formDataObj.append('productname', formData.productname);

//       const response = await axios.post('https://api.novetheitaliankitchen.in/index.php/Api/image', formDataObj, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });

//       console.log('File upload response:', response.data);
//     } catch (error) {
//       console.error('Error uploading file:', error);
//     }
//   };

//   return (
//     <div>

//       <input type="file" onChange={handleFileChange} />
//       <button onClick={handleFileUpload}>Upload File</button>
//     </div>
//   );
// };

// export default Testing;



// import React, {useState, useEffect} from 'react';
// import axios from 'axios';

// function Testing() {
//     const [post, setPost] = useState({});
//     const id = 1;

//     const handleChange = ({target}) => {
//         const {name, value} = target;
//         setPost({...post, [name]: value});
//         console.log(post);
//     };

//     const loadData=()=>{
//       fetch("https://api.novetheitaliankitchen.in/index.php/Api/Productdata", {
//           method: "POST",
//           body: JSON.stringify({
//               pid: 35,
//           }),
//           headers: {
//           Accept: "application/json",
//           "Content-type": "application/json; charset=UTF-8",
//           },
//       })
//           .then((res) => res.json())
//           .then((result) => {
//           //console.log(result);

//               if (result.data!=="") {
//                   //setProdName(result.data.prodInfo.name)
//                   setPost(result.data.prodInfo);
                 
//               }
//           })
//       .catch((err) => {
//           //this.setState({ error: 'Invalid username or password' });
//           console.log(err.message);

//       });
  
// }

// useEffect(() => {

//   loadData();

  
// }, []);
//     const handleSubmit = async e => {
//         e.preventDefault();

//         const editDataById = async () => {
//             try {
//                 const response = await axios.put(`https://jsonplaceholder.typicode.com/posts/${id}`, {
//                     method: 'PUT',
//                     body: JSON.stringify({
//                         id: id,
//                         title: post.name,
//                         body: post.description,
//                         userId: 1
//                     }),
//                     headers: {
//                         "Content-type": "application/json; charset=UTF-8"
//                     }
//                 })
//                     .then(response => response.json())
//                     .then(json => console.log(json));
//                 console.warn(response.data);
//             } catch (error) {
//                 console.warn(error);
//             }
//         };
//         editDataById();
//     };
//     return (
//         <div className='container'>
//             <div className='row'>
//                 <div className='col-4'>
//                     <form onSubmit={handleSubmit}>
//                         <div className="form-group">
//                             <label htmlFor="name">Title</label>
//                             <input type="text" name='name' value={post.name} onChange={handleChange}
//                                    className="form-control" id="name"/>
//                         </div>
//                         <div className="form-group">
//                             <label htmlFor="position">Body</label>
//                             <input type="text" name='description' value={post.description}
//                                    onChange={handleChange} className="form-control" id="description"/>
//                         </div>
//                         <button type="submit" className="btn btn-primary">Submit</button>
//                     </form>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default Testing;


// import React, { useState } from 'react';

// const Testing = () => {
//   const [data, setData] = useState([
//     { id: 1, name: 'Item 1' },
//     { id: 2, name: 'Item 2' },
//     { id: 3, name: 'Item 3' },
//   ]);

//   const removeItem = (idToRemove) => {
//     // Find the index of the item with the matching id
//     const indexToRemove = data.findIndex(item => item.id === idToRemove);

//     if (indexToRemove !== -1) {
//       // Create a new array without the item at the found index
//       const newData = [...data.slice(0, indexToRemove), ...data.slice(indexToRemove + 1)];

//       // Update the state with the new array
//       setData(newData);
//     }
//   };

//   return (
//     <div>
//       <ul>
//         {data.map(item => (
//           <li key={item.id}>
//             {item.name}
//             <button onClick={() => removeItem(item.id)}>Remove</button>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default Testing;


// import React from "react";
// import cloneDeep from "lodash/cloneDeep";
// import throttle from "lodash/throttle";
// import Pagination from "rc-pagination";
// import "rc-pagination/assets/index.css";
// import './DataTable.css';


// const allData = [
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale2",
//     parentId: "123",
//     campaignType: "Inapp",
//     status: "Failed",
//     channel: "iOS",
//     action: ":"
//   },
//   {
//     name: "Sale34",
//     parentId: "45",
//     campaignType: "Email",
//     status: "sent",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   }
// ];


// const tableHead = {
//   name: "Campaign Name",
//   parentId: "Campaign Id",
//   campaignType: "Type",
//   status: "Status",
//   channel: "Channel",
//   action: "Actions"
// };

// const Testing = () => {
//   const countPerPage = 10;
//   const [value, setValue] = React.useState("");
//   const [currentPage, setCurrentPage] = React.useState(1);
//   const [collection, setCollection] = React.useState(
//     cloneDeep(allData.slice(0, countPerPage))
//   );
//   const searchData = React.useRef(
//     throttle(val => {
//       const query = val.toLowerCase();
//       setCurrentPage(1);
//       const data = cloneDeep(
//         allData
//           .filter(item => item.name.toLowerCase().indexOf(query) > -1)
//           .slice(0, countPerPage)
//       );
//       setCollection(data);
//     }, 400)
//   );

//   React.useEffect(() => {
//     if (!value) {
//       updatePage(1);
//     } else {
//       searchData.current(value);
//     }
//   }, [value]);

//   const updatePage = p => {
//     setCurrentPage(p);
//     const to = countPerPage * p;
//     const from = to - countPerPage;
//     setCollection(cloneDeep(allData.slice(from, to)));
//   };

//   const tableRows = rowData => {
//     const { key, index } = rowData;
//     const tableCell = Object.keys(tableHead);
//     const columnData = tableCell.map((keyD, i) => {
//       return <td key={i}>{key[keyD]}</td>;
//     });

//     return <tr key={index}>{columnData}</tr>;
//   };

//   const tableData = () => {
//     return collection.map((key, index) => tableRows({ key, index }));
//   };

//   const headRow = () => {
//     return Object.values(tableHead).map((title, index) => (
//       <td key={index}>{title}</td>
//     ));
//   };

//   return (
//     <>
//       {/* <div class="search">
//         <input
//           placeholder="Search Campaign"
//           value={value}
//           onChange={e => setValue(e.target.value)}
//         />
//       </div> */}
//       <table>
//         <thead>
//           <tr>{headRow()}</tr>
//         </thead>
//         <tbody className="trhover">{tableData()}</tbody>
//       </table>
//       <Pagination
//         pageSize={countPerPage}
//         onChange={updatePage}
//         current={currentPage}
//         total={allData.length}
//       />
//     </>
//   );
// };
// export default Testing;


//filters
// import React, { useState } from 'react';

// const Testing = () => {
//   const options = ['Veg', 'Nonveg', 'New']; // Sample checkbox options

//   const [checkedItems, setCheckedItems] = useState({}); // State to hold checked status of checkboxes

//   // Function to handle checkbox change
//   const handleCheckboxChange = (event) => {
//     const { name, checked, value } = event.target;
//     const updatedCheckedItems = { ...checkedItems, [name]: checked ? value : undefined };
//     setCheckedItems(updatedCheckedItems);
//     console.log(updatedCheckedItems);
//   };

//   return (
//     <div>
//       {options.map((option, index) => (
//         <label key={index}>
//           <input
//             type="checkbox"
//             name={option}
//             value={option}
//             checked={checkedItems[option] || false}
//             onChange={handleCheckboxChange}
//           />
//           {option}
//         </label>
//       ))}

//         <label>
//           <input
//             type="checkbox"
//             name="veg"
//             value="1"
//             checked={checkedItems['veg'] || false}
//             onChange={handleCheckboxChange}
//           />
//           veg
//         </label>

//         <label>
//           <input
//             type="checkbox"
//             name="Nonveg"
//             value="1"
//             checked={checkedItems['Nonveg'] || false}
//             onChange={handleCheckboxChange}
//           />
//           Nonveg
//         </label>
//     </div>
//   );
// };
// export default Testing;

// import { Person } from "schema-dts";
// import { helmetJsonLdProp } from "react-schemaorg";
// import { Helmet } from "react-helmet";
// import {Helmet} from "react-helmet";
// function Testing(){

//   const ldJson = {
//   "@context": "https://schema.org",
//   "@type": "BreadcrumbList",
//   "itemListElement": [{
//     "@type": "ListItem",
//     "position": 1,
//     "name": "Blogs",
//     "item": "https://www.speblog.org"
//   },{
//     "@type": "ListItem",
//     "position": 2,
//     "name": "venky",
//     "item": window.location.href
//   }]
// };
// return (

// <>
// <Helmet>
// <script type="application/ld+json">
//   {JSON.stringify(ldJson)}
// </script>
// </Helmet>
// </>
// )
// }

// export default Testing;

// import React, { useState } from 'react';


// function Testing() {
//   const [tags, setTags] = useState([]);
//   const [inputValue, setInputValue] = useState('');

//   const handleInputChange = (event) => {
//     setInputValue(event.target.value);
//   };

//   const handleInputKeyDown = (event) => {
//     if (event.key === 'Enter' || event.key === ',') {
//       event.preventDefault(); // Prevent default behavior (form submission)
//       const newTag = inputValue.trim();
//       if (newTag) {
//         setTags([...tags, newTag]);
//         setInputValue(''); // Clear input after adding tag
//       }
//     }
//   };

//   const removeTag = (indexToRemove) => {
//     setTags(tags.filter((_, index) => index !== indexToRemove));
//   };

//   return (
//     <div className="App">
//       <div className="tags-input-container">
//         <div id="tags" className="tags">
//           {tags.map((tag, index) => (
//             <div key={index} className="tag">
//               {tag} <span className="tag-remove" onClick={() => removeTag(index)}>x</span>
//             </div>
//           ))}
//           <input
//             type="text"
//             id="tagInput"
//             value={inputValue}
//             onChange={handleInputChange}
//             onKeyDown={handleInputKeyDown}
//             className="tag-input"
//             placeholder="Add tags..."
//           />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Testing;


import React, { useState,useEffect } from 'react';

const Testing = () => {
  // Sample data for the table

const [data, setData] = useState([
  { id: 1, name: 'John Doe', age: 30, email: 'john@example.com' },
  { id: 2, name: 'Jane Smith', age: 25, email: 'jane@example.com' },
  { id: 3, name: 'Bob Johnson', age: 40, email: 'bob@example.com' },
  // Additional sample data...
]);




const getData=(event)=>{
  fetch("https://reqres.in/api/users?page=1", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((res) => res.json())
    .then((resp) => {
      let data = resp.data.data.sort((a, b) =>
      a.first_name.localeCompare(b.first_name)
    );
     let cols = Object.keys(data[0]).map((key) => {
       return {
         Header: key.toUpperCase(),
         accessor: key
       };
     });
     setData(data);
     //setColumns(cols);

    })
  .catch((err) => {
    console.log(err.message);
  });
}

useEffect(() => {

  getData();
  }, []);


// Pagination
const [currentPage, setCurrentPage] = useState(1);
const itemsPerPage = 2;
const totalPages = Math.ceil(data.length / itemsPerPage);

const handleClick = (type) => {
  if (type === 'prev') {
    setCurrentPage(currentPage - 1);
  } else if (type === 'next') {
    setCurrentPage(currentPage + 1);
  }
};

const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

return (
  <div>
    <h2>Data Table</h2>
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Age</th>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
        {currentItems.map((row) => (
          <tr key={row.id}>
            <td>{row.id}</td>
            <td>{row.name}</td>
            <td>{row.age}</td>
            <td>{row.email}</td>
          </tr>
        ))}
      </tbody>
    </table>
    <div>
      <button
        onClick={() => handleClick('prev')}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      <span>{`Page ${currentPage} of ${totalPages}`}</span>
      <button
        onClick={() => handleClick('next')}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  </div>
);
};

export default Testing;