import React, { useEffect, useState } from "react";
import Breadcrumb from './Breadcrumb';
import Productslider from './Productslider';
import axios from 'axios';
import { Link } from 'react-router-dom';

function Addproductcontent() {

    const [categoryDropdown, setcategoryDropdown] = useState([]);
    const [maincategoryDropdown, setMaincategoryDropdown] = useState([]);
    const [typeDropdown, settypeDropdown] = useState([]);
    const [inputs, setInputs] = useState([]);
    const [price, setPrice] = useState([]);
    const [file, setFile] = useState(null);
    const [counter, setCounter] = useState(1);
    //const [available, setAvailable] = useState(["Yes","No"]);


    const [formData, setFormData] = useState({
        productname: '',
        category: 1,
        type: 1,
        price: '',
        status:'',
        description:'',
        image:'',
        addon:[],
        available:'Yes',
        main_category_id:'',
        keywords:''

      });
    
    useEffect(() => {
       mainCatDropdown();
       //CategoryDropdown();
       TypeDropdown();
       
    }, []);

    const mainCatDropdown=()=>{
        // fetch("https://api.novetheitaliankitchen.in/index.php/Api/maincategories", {
        //     method: "GET",
        //     headers: {
        //       Accept: "application/json",
        //       "Content-Type": "multipart/form-data",
        //       //"Content-type": "application/json; charset=UTF-8",
        //     },
        //   })
        //     .then((res) => res.json())
        //     .then((result) => {
        //       //console.log(result);
        //       if (result.data != "") {
        //         const data = result.data;
        //         setMaincategoryDropdown(data);
        //         handlemaincatChange();
    
        //       }
        //     })
        //   .catch((err) => {
        //     console.log(err.message);
        //   });

        fetch("https://api.novetheitaliankitchen.in/index.php/Api/subcategory", {
          method: "POST",
          body: JSON.stringify({
              main_category_id: 1,
          }),
          headers: {
            Accept: "application/json",
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((res) => res.json())
          .then((result) => {
            //console.log(result);
            if (result.data != "") {
              setcategoryDropdown(result.data);
            }
          })
        .catch((err) => {
          setcategoryDropdown([]);
          console.log(err.message);
        });
}

//  const CategoryDropdown=()=>{
//     fetch("https://api.novetheitaliankitchen.in/index.php/Api/categories", {
//         method: "GET",
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "multipart/form-data",
//           //"Content-type": "application/json; charset=UTF-8",
//         },
//       })
//         .then((res) => res.json())
//         .then((result) => {
//           //console.log(result);
//           if (result.data != "") {
//             const data = result.data;
//             setcategoryDropdown(data);

//           }
//         })
//       .catch((err) => {
//         console.log(err.message);
//       });
//   }

  const TypeDropdown=()=>{
    fetch("https://api.novetheitaliankitchen.in/index.php/Api/producttype", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          //console.log(result);
          if (result.data != "") {
            const data = result.data;
            settypeDropdown(data);

          }
        })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const handleAddmore=()=>{
    setCounter(counter + 1);
    console.log(counter);
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputChangeloop = (index, value) => {
    const updatedData = [...inputs];
    updatedData[index] = value;
    setInputs(updatedData);
  };

  const handleInputChangeprice = (index, value) => {
    const updatedData = [...price];
    updatedData[index] = value;
    setPrice(updatedData);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
 
const handlemaincatChange=(event)=>{

    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    fetch("https://api.novetheitaliankitchen.in/index.php/Api/subcategory", {
        method: "POST",
        body: JSON.stringify({
            main_category_id: event.target.value,
        }),
        headers: {
          Accept: "application/json",
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          //console.log(result);
          if (result.data != "") {
            setcategoryDropdown(result.data);
          }
        })
      .catch((err) => {
        setcategoryDropdown([]);
        console.log(err.message);
      });
}

  const handleSubmit = async(event) => {
    event.preventDefault();
    // Now you can use formData as needed, e.g., send it to a server, update state, etc.
    //console.log(formData.addon);
  //console.log('Submitted form data:', formData);
    // const updatedData = formData;
    // updatedData.addon = inputs;
    // setFormData(updatedData);
    // console.log('Submitted form data123:', formData);
    // Set the state with the updated array
    // console.log('Submitted :', inputs);
    // console.log('Submitted :', price);

    try {
        const formDataObj = new FormData();
        formDataObj.append('file', file);
        formDataObj.append('name', formData.productname);
        formDataObj.append('category_id', formData.category);
        formDataObj.append('type_id', formData.type);
        formDataObj.append('description', formData.description);
        formDataObj.append('current_price', formData.price);
        formDataObj.append('product_status', formData.status);
        formDataObj.append('available', formData.available);
        formDataObj.append('main_category_id', 1);
        formDataObj.append('keywords', formData.keywords);
        inputs.forEach((inputs, index) => {
            formDataObj.append(`add_on_name[${index}]`, inputs);
        });
    
        price.forEach((price, index) => {
            formDataObj.append(`add_on_price[${index}]`, price);
        });

  
        const response = await axios.post('https://api.novetheitaliankitchen.in/index.php/Api/addproduct', formDataObj, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        alert('Data added successfully');
        window.location.href = '/menu-grid';
        //console.log('File upload response:', response.data);
      } catch (error) {
        console.error('Error uploading file:', error);
      }


  };

        return (
            <div className="ms-content-wrapper">
                <div className="row">
                    <div className="col-md-12" >
                     <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pl-0">
                            <li className="breadcrumb-item"><Link to="/"><i className="material-icons">home</i> Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Add Product</li>
                        </ol>
                    </nav>
                      
                    </div>
                    <div className="col-xl-12 col-md-12">
                        <div className="ms-panel ms-panel-fh">
                            <div className="ms-panel-header">
                                <h6>Add Product Form</h6>
                            </div>
                            <div className="ms-panel-body">
                                <form className="needs-validation clearfix"  onSubmit={handleSubmit} key="addform">
                                    <div className="form-row">
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="validationCustom18">Product Name</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" id="validationCustom18" placeholder="Product Name"  required name="productname" value={formData.productname} onChange={handleInputChange}/>
                                                <div className="valid-feedback">
                                                    Looks good!
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6 mb-3">
                                            <label htmlFor="validationCustom18">Select Catagory</label>
                                            <div className="input-group">
                                                <select className="form-control" required id="validationCustom18"  name="main_category_id" value={formData.main_category_id} onChange={handlemaincatChange}>
                                                <option value="">--Select--</option>
                                                {maincategoryDropdown.map((item, i) => ( 
                                                    <option value={item.id} key={i} >{item.category_name}</option>
                                                 ))}
                                                    
                                                </select>
                                                <div className="invalid-feedback">
                                                    Please select a Catagory.
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="validationCustom22">Select Catagory</label>
                                            <div className="input-group">
                                                <select className="form-control" required id="validationCustom22"  name="category" value={formData.category} onChange={handleInputChange}>
                                                <option value="">--Select--</option>
                                                {categoryDropdown.map((item, i) => ( 
                                                    <option value={item.id} key={i} >{item.name}</option>
                                                 ))}
                                                    
                                                </select>
                                                <div className="invalid-feedback">
                                                    Please select a Catagory.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="validationCustom23">Type</label>
                                            <div className="input-group">
                                                <select className="form-control" id="validationCustom23" required name="type" value={formData.type} onChange={handleInputChange}>
                                                {typeDropdown.map((item, i) => ( 
                                                    <option value={item.id} key={i}>{item.type_name}</option>
                                                 ))}
                                                </select>
                                                
                                            </div>
                                        </div>
                                      
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="validationCustom25">Price</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" id="validationCustom25" placeholder="10" required name="price" value={formData.price} onChange={handleInputChange}/>
                                                <div className="invalid-feedback">
                                                    Price
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="validationCustom26">Status</label>
                                            <div className="input-group">
                                                <select className="form-control"   name="status" value={formData.status} onChange={handleInputChange}>
                                                <option value="">--Select--</option>
                                                <option value="Must Try!">Must Try!</option>
                                                <option value="NEW">New</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="validationCustom27">Available</label>
                                            <div className="input-group">
                                            <select className="form-control" required name="available" value={formData.available} onChange={handleInputChange}>
                                              <option value="Yes">Yes</option>
                                              <option value="No">No</option>
                                            </select>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="validationCustom12">Product Image</label>
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" name="image" id="validatedCustomFile" onChange={handleFileChange}/>
                                                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Images...</label>
                                                <div className="invalid-feedback">Example invalid custom file feedback</div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="validationCustom12">Description</label>
                                            <div className="input-group">
                                                <textarea rows={5} id="validationCustom12" className="form-control" placeholder="Message"  name="description" value={formData.description} onChange={handleInputChange}/>
                                                <div className="invalid-feedback">
                                                    Please provide a message.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="keywords">Keywords</label>
                                            <div className="input-group">
                                                <textarea rows={3} id="keywords" className="form-control" placeholder="Message"  name="keywords" value={formData.keywords} onChange={handleInputChange}/>
                                               
                                            </div>
                                        </div>
                                    

                                        <div className="col-md-12 mb-2">
                                            <div className="d-flex">
                                                <div className="d-inline-block col-md-10"> <h6>Add-ons</h6> </div>
                                                <div className="d-inline-block col-md-2"> <i className="fa fa-plus-circle fs-16" onClick={handleAddmore}/> </div>
                                            </div>
                                        </div>

                                      
                             

                                                                           
                                {Array.from(Array(counter)).map((c, i) => {
                                return (
                                    <>
                      
                                     <div className="col-md-12" key={i} >  
                                  <div className="row" >
                                    <div className="col-md-6 mb-3" >
                                            <label htmlFor="validationCustom24">Name </label>
                                            <div className="input-group" >
                                                <input type="text" className="form-control"   placeholder="Pizza"  name="addon" onChange={(e) => handleInputChangeloop(i, e.target.value)}/>
                                                <div className="invalid-feedback">
                                                Name
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3" >
                                            <label htmlFor="validationCustom25">Price</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" name="option1"  placeholder="10"  onChange={(e) => handleInputChangeprice(i, e.target.value)}/>
                                                <div className="invalid-feedback">
                                                    Price
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                          
                                        </>
                                    
                                    );
                                })}
                                        
                                        <div className="ms-panel-header new">
                                          <button className="btn btn-primary d-block" type="submit" >Save</button>
                                        </div>
                                     
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-xl-6 col-md-12 hidden" >
                        <div className="row">
                            <div className="col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-header">
                                        <h6>Product </h6>
                                    </div>
                                    <div className="ms-panel-body">
                                        <Productslider/>
                                        
                                    </div>
                                    <div className="ms-panel-header new">
                                        <p className="medium">Status Available</p>
                                        <div>
                                            <label className="ms-switch">
                                                <input type="checkbox" />
                                                <span className="ms-switch-slider round" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="ms-panel-header new">
                                        <p className="medium">Discount Active</p>
                                        <div>
                                            <label className="ms-switch">
                                                <input type="checkbox" defaultChecked />
                                                <span className="ms-switch-slider round" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="ms-panel-header new">
                                        <button className="btn btn-secondary d-block" type="submit">Save</button>
                                        <button className="btn btn-primary d-block" type="submit">Save and Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

        );

}

export default Addproductcontent;