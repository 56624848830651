import React, { Component ,useEffect,useState} from 'react';
import Breadcrumb from './Breadcrumb';
import { Carousel } from 'react-bootstrap'
import { useParams } from "react-router";

function  Detailcontent() {
    const params = useParams();
    const id= params.id.toString();
    const [proddetails, setProddetails] = useState([]);

    useEffect(() => {
        loadPrdoductdetails();
        
     }, []);

    const loadPrdoductdetails=()=>{

    fetch("https://api.novetheitaliankitchen.in/index.php/Api/productdetails", {
           method: "POST",
           body: JSON.stringify({
                pid: id,
            }),
           headers: {
             Accept: "application/json",
             "Content-type": "application/json; charset=UTF-8",
           },
         })
           .then((res) => res.json())
           .then((result) => {
            //console.log(result);

                if (result.data!=="") {
                    setProddetails(result.data);
                    //console.log(result);

                }
           })
         .catch((err) => {
           //this.setState({ error: 'Invalid username or password' });
           console.log(err.message);

         });
   }
    
   const RemoveProduct=(pid)=>{

    fetch("https://api.novetheitaliankitchen.in/index.php/Api/removeproduct", {
        method: "POST",
        body: JSON.stringify({
            id: pid,
        }),
        headers: {
          Accept: "application/json",
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((res) => res.json())
        .then((result) => {
            window.location.href = '/menu-grid';
        })
      .catch((err) => {
      });
}
    //console.log(params.id.toString());
        return (
            <div className="ms-content-wrapper">
                <div className="row">
                    <div className="col-md-12">
                        <Breadcrumb />
                    </div>
                    <div className="col-md-6">
                        <div className="ms-panel">
                            <div className="ms-panel-header">
                                <h6>Product Details</h6>
                            </div>
                            <div className="ms-panel-body">
                              
                                 
                                        <img className="d-block w-100" src={proddetails.image} alt="First slide" />
                                        <div className="carousel-caption d-none d-md-block">
                                            <h3 className="text-white">{proddetails.name}</h3>
                                        </div>
                                  
                                    
                            </div>
                        </div>
                    </div>
                    <div className=" col-md-6">
                        <div className="ms-panel ms-panel-fh">
                            <div className="ms-panel-body">
                                <h4 className="section-title bold">Product Info</h4>
                                <table className="table ms-profile-information">
                                    <tbody>
                                        <tr>
                                            <th scope="row">Price</th>
                                            <td>{proddetails.current_price}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Product Category</th>
                                            <td>{proddetails.type_name}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Availiblity</th>
                                            <td><span className="badge badge-pill badge-primary">{proddetails.available}</span></td>
                                        </tr>
                                    
                                        <tr>
                                            <th scope="row">Status</th>
                                            <td><span className="badge badge-pill badge-primary">{proddetails.product_status}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="new">
                                <a href={"/edit-product/"+id}><button type="button" className="btn btn-primary">Edit</button></a>
                                    <button type="button" className="btn btn-secondary" onClick={(e) => RemoveProduct(id)}>Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" col-md-12">
                        <div className="ms-panel ms-panel-fh">
                            <div className="ms-panel-body">
                                <h4 className="section-title bold">Product Details </h4>
                                <p className="description">{proddetails.description}</p>
                            </div>
                            {/* <div className="ms-quick-stats">
                                <div className="ms-stats-grid">
                                    <i className="fa fa-bullhorn" />
                                    <p className="ms-text-dark">1,033</p>
                                    <span>Today Order</span>
                                </div>
                                <div className="ms-stats-grid">
                                    <i className="fa fa-heart" />
                                    <p className="ms-text-dark">3,039</p>
                                    <span>Favourite</span>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

        );
    
}

export default Detailcontent;