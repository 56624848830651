import React, { useEffect, useState } from "react";
import Breadcrumb from './Breadcrumb';
import search from "../../../frontend/search.png";

function Gridcontent() {
    const [gridboxes, setGridboxesdata] = useState([]);
    const [productData, setproductData] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
        productsData();
   }
  , []);

    const RemoveProduct=(pid)=>{
        setGridboxesdata((oldValues) => oldValues.filter((item) => item.id !== pid));

        fetch("https://api.novetheitaliankitchen.in/index.php/Api/removeproduct", {
            method: "POST",
            body: JSON.stringify({
                id: pid,
            }),
            headers: {
              Accept: "application/json",
              "Content-type": "application/json; charset=UTF-8",
            },
          })
            .then((res) => res.json())
            .then((result) => {
              productsData();
            })
          .catch((err) => {
          });
    }

    const productsData=()=>{
        fetch("https://api.novetheitaliankitchen.in/index.php/Api/allProducts", {
          method: "POST",
          body: JSON.stringify({
            main_category_id: 1,
        }),
          headers: {
            Accept: "application/json",
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((res) => res.json())
          .then((result) => {
            //console.log(result);
            if (result.data != "") {
              const data = result.data;
              setGridboxesdata(data);
            }
          })
        .catch((err) => {
          console.log(err.message);
        });
    }

    const handleStatus=(status)=>{
       if(status=="Must Try!"){
        return "badge badge-primary";
       }else{
        return "badge badge-success";
       }
    }
     
    const handleAvailable=(status)=>{
      if(status=="Non-veg"){
        return "badge badge-primary";
       }else{
        return "badge badge-success";
       }
    }

    let timeoutId;
    const handleSearch = (event) => {
      const { value } = event.target;
      setSearchInput(value);
      clearTimeout(timeoutId); // Clear any previously scheduled call
        searchData();
    };

    const searchData=(event)=>{
      fetch("https://api.novetheitaliankitchen.in/index.php/Api/searchData", {
        method: "POST",
        body: JSON.stringify(
          { 
            search:searchInput,
            main_category_id: 1
          }),
        headers: {
          Accept: "application/json",
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          //console.log(result);
          if (result.data != "") {
            setGridboxesdata(result.data);
          }
        })
      .catch((err) => {
        console.log(err.message);
      });
    }

    const handleInputChangeloop = (index, value,id) => {

        fetch("https://api.novetheitaliankitchen.in/index.php/Api/availabilityupdate", {
          method: "POST",
          body: JSON.stringify(
            { 
              status:value,
              pid: id
            }),
          headers: {
            Accept: "application/json",
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((res) => res.json())
          .then((result) => {
            productsData();
         
          })
        .catch((err) => {
          console.log(err.message);
        });

    };

        return (
          
            // <div className="ms-content-wrapper">
            //     <div className="row">
            //         <div className="col-md-12">
            //             <Breadcrumb />
            //             <div className="heading"><h3>Dolci</h3></div>
            //             <div className="row">
            //                 {gridboxes.map((item, i) => (
            //                     <div key={item.id} className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            //                         <div className="ms-card">
            //                             <a href={"/product-detail/"+item.id}>
            //                               <div className="ms-card-img">
            //                                 <img src={item.image} alt="card_img" />
            //                               </div>
            //                             </a>
            //                             <div className="ms-card-body">
            //                                 <a href={"/product-detail/"+item.id}>
            //                                 <div className="new">
            //                                     <h6 className="mb-0">{item.name} </h6>
            //                                     {/* <h6 className="ms-text-primary mb-0">{item.current_price} &#8377;</h6> */}
            //                                 </div>
            //                                 <div className="new meta">
            //                                     <p>Price : <span className="ms-text-primary font-weight-bold">{item.current_price} &#8377; </span></p>
            //                                     <span className={handleStatus(item.product_status)}>{item.product_status}</span>
            //                                 </div>
            //                                 <p style={{display: '-webkit-box',
            //                                     WebkitLineClamp: 3,
            //                                     WebkitBoxOrient: 'vertical',  
            //                                     overflow: 'hidden'
            //                                   }}>{item.description}</p>
            //                                 </a>
            //                                 <div className="new mb-0">
            //                                     <button type="button" className="btn grid-btn mt-0 btn-sm btn-primary" onClick={(e) => RemoveProduct(item.id)} >Remove</button>
            //                                     <a href={"/edit-product/"+item.id}><button type="button" className="btn grid-btn mt-0 btn-sm btn-secondary">Edit</button></a>
            //                                 </div>
            //                             </div>
            //                         </div>
            //                     </div>
            //                 ))}
            //             </div>
            //         </div>
            //     </div>
            // </div>

            <div className="ms-content-wrapper">
               <form className="searchform" >
                      <input type="text"  onChange={handleSearch} name="search" placeholder="Search here..." /> 
                      <div id="search"><img src={search}/></div>
               </form>
    <div className="row">
        <div className="col-md-12">
            <Breadcrumb />
            {gridboxes.map((item, i) => (
                <>
                {item.sub_cat.length > 0 &&
                <React.Fragment key={i}>
                    <div className="heading"><h3>{item.category}</h3></div>
                    <div className="row">
                        {item.sub_cat.map((sub_cat, index) => (
                            <div key={sub_cat.id} className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div className="ms-card">
                                    <a href={"/product-detail/" + sub_cat.id}>
                                        <div className="ms-card-img">
                                            <img src={sub_cat.image} alt="card_img" />
                                        </div>
                                    </a>
                                    <div className="ms-card-body">
                                        <a href={"/product-detail/" + sub_cat.id}>
                                            <div className="new">
                                                <h6 className="mb-0">{sub_cat.name} </h6>
                                                {/* <h6 className="ms-text-primary mb-0">{item.current_price} &#8377;</h6> */}
                                            </div>
                                            <div className="new meta">
                                                <p>Price : <span className="ms-text-primary font-weight-bold">{sub_cat.current_price} &#8377; </span></p>
                                                <span className={handleStatus(sub_cat.product_status)}>{sub_cat.product_status}</span>
                                            </div>

                                            <div className="new meta">
                                                <p>Category : </p>
                                                <span className={handleAvailable(sub_cat.type_name)}>{sub_cat.type_name}</span>
                                            </div>
                                         
                                            <div className="mb-3" key={'cat'+sub_cat.id}>
                                              <span>Availability </span>
                                              <label className="ms-switch float-right">
                                                <input 
                                                  type="checkbox" 
                                                  checked={sub_cat.available === 'Yes'} 
                                                  onChange={() => handleInputChangeloop(i, sub_cat.available === 'Yes' ? 'No' : 'Yes',sub_cat.id)} // Toggle between 'Yes' and 'No'
                                                /> 
                                                <span className="ms-switch-slider round" />
                                              </label> 
                                            </div>

                                       
                                            <p style={{
                                                display: '-webkit-box',
                                                WebkitLineClamp: 3,
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden'
                                            }}>{sub_cat.description}</p>
                                        </a>
                                        <div className="new mb-0">
                                            <button type="button" className="btn grid-btn mt-0 btn-sm btn-primary" onClick={(e) => RemoveProduct(sub_cat.id)} >Remove</button>
                                            <a href={"/edit-product/" + sub_cat.id}><button type="button" className="btn grid-btn mt-0 btn-sm btn-secondary">Edit</button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </React.Fragment>
                }
              </>
            ))}
        </div>
    </div>
</div>


        );
    
}

export default Gridcontent;