import React, { useEffect, useState } from "react";
import Breadcrumb from './Breadcrumb';
import Productslider from './Productslider';
import axios from 'axios';

function Addcategorycontent() {


    const [maincategoryDropdown, setMaincategoryDropdown] = useState([]);

    const [formData, setFormData] = useState({
        category: '',
        main_category_id:''
      });
    
    // useEffect(() => {
    //    mainCatDropdown();
    // }, []);

//     const mainCatDropdown=()=>{
//         fetch("https://api.novetheitaliankitchen.in/index.php/Api/maincategories", {
//             method: "GET",
//             headers: {
//               Accept: "application/json",
//               "Content-Type": "multipart/form-data",
//               //"Content-type": "application/json; charset=UTF-8",
//             },
//           })
//             .then((res) => res.json())
//             .then((result) => {
//               //console.log(result);
//               if (result.data != "") {
//                 const data = result.data;
//                 setMaincategoryDropdown(data);
//                 handlemaincatChange();
    
//               }
//             })
//           .catch((err) => {
//             console.log(err.message);
//           });
// }



  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


const handlemaincatChange=(event)=>{

    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });

}

  const handleSubmit = async(event) => {
    event.preventDefault();

    try {
        const formDataObj = new FormData();
        formDataObj.append('category', formData.category);
        formDataObj.append('main_category_id', 1);

        const response = await axios.post('https://api.novetheitaliankitchen.in/index.php/Api/addsubcategory', formDataObj, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        window.location.href = '/category';
        //console.log('File upload response:', response.data);
      } catch (error) {
        console.error('Error uploading file:', error);
      }


  };

        return (
            <div className="ms-content-wrapper">
                <div className="row">
                    <div className="col-md-12" >
                        <Breadcrumb/>
                        <div className="alert alert-success" role="alert" style={{display:  'none' }}>
                            <strong>Well done!</strong> You successfully read this important alert message.
                        </div>
                    </div>
                    <div className="col-xl-12 col-md-12">
                        <div className="ms-panel ms-panel-fh">
                            <div className="ms-panel-header">
                                <h6>Add Product Form</h6>
                            </div>
                            <div className="ms-panel-body">
                                <form className="needs-validation clearfix"  onSubmit={handleSubmit} key="addform">
                                    <div className="form-row">
                                        
                                        {/* <div className="col-md-12 mb-3">
                                            <label htmlFor="validationCustom18">Select Main Category</label>
                                            <div className="input-group">
                                                <select className="form-control" required id="validationCustom18"  name="main_category_id" value={formData.main_category_id} onChange={handlemaincatChange}>
                                                <option value="">--Select--</option>
                                                {maincategoryDropdown.map((item, i) => ( 
                                                    <option value={item.id} key={i} >{item.category_name}</option>
                                                 ))}
                                                    
                                                </select>
                                                <div className="invalid-feedback">
                                                    Please select a Catagory.
                                                </div>
                                            </div>
                                        </div> */}
                                      
                                        
                                      
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="category">Category</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" id="category" placeholder="Enter sub category" required name="category" value={formData.category} onChange={handleInputChange}/>
                                                
                                            </div>
                                        </div>
              
                                        <div className="ms-panel-header new">
                                          <button className="btn btn-primary d-block" type="submit" >Save</button>
                                        </div>
                                     
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
              
                </div>
            </div>

        );

}

export default Addcategorycontent;