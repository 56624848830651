import React, { useEffect, useState } from "react";
import logo from './logo.png';

import service1 from './service-1.png';
import service2 from './service-2.png';
import service3 from './service-3.png';
import service4 from './service-4.png';
import bgheader from './bg-header.png';
import aboutus from './aboutus.png';
import Modal from './Modal.js';
import Header from './Header.js';
import Stickyheader from './Stickyheader.js';
import "./App.css";
import Content from './Popup.js';
import pin from './pin-white.png';
import instagram from './instagram-logo.png';
import whatsapp from './whatsapp (2).png';
import { Helmet } from "react-helmet";
import landing_logo from "./landing_logo.png";



const Landing = () => {

  const [logo, setLogo] = useState('');

  useEffect(() => {
    loadLogo();
 }, []);


 const loadLogo=()=>{
  fetch("https://api.novetheitaliankitchen.in/index.php/Api/logo", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        //console.log(result);
        if (result.data != "") {
          const data = result.data;
          setLogo(data);
        }
      })
    .catch((err) => {
      console.log(err.message);
    });
}

const ldJson = {
  
    "@context": "https://schema.org",
    "@type": "Restaurant",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Hyderabad",
      "addressRegion": "IN",
      "postalCode": "500034",
      "streetAddress": "28, Crafts Council of Telangana, 1/3/1, Road No. 12, NBT Nagar, Banjara Hills"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4",
      "reviewCount": "250"
    },
    "name": "Nove - The Italian Kitchen",
    "openingHours": [
      "Mo-Sa 12:00-11:",
      "sa-su 09:00-11:00",

    ],
    "priceRange": "₹",
    // "servesCuisine": [
    //   "Middle Eastern",
    //   "Mediterranean"
    // ],
    "telephone": "070958 76222",
    "url": "https://novetheitaliankitchen.in/"
  
};


  return (
    <>
    <Helmet>

        <title>{logo.title}</title>
        <link id="favicon" rel="icon" href={logo.image} type="image/x-icon"/>
        <meta
          name="description"
          content="Discover the essence of Nove - The Italian Kitchen - where flavors come alive. Explore our menu for a culinary journey like no other. Indulge today!"
        />
        <meta
          name="keywords"
          content="Nove - The Italian Kitchen"
        />

    <script type="application/ld+json">
      {JSON.stringify(ldJson)}
    </script>
    </Helmet>  
<div className="main-page-cont">
        <div className="logo">
          <img src={landing_logo} alt="logo" />
        </div>
        {/* <div className="social-icons">
          <ul>
            <li>
              <a href="#" alt="" title>
                <img src={pin} alt="" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/nove_hyd/?hl=en" alt="" title>
                <img src={instagram} alt="" />
              </a>
            </li>
            <li>
              <a href="#" alt="" title>
                <img src={whatsapp} alt="" />
              </a>
            </li>
          </ul>
        </div> */}
        <div className="menu-btns mt-5">
          <ul>
            <li><a href="/home" alt="" title>Dine-In Menu</a></li>
            <li><a href="https://www.zomato.com/hyderabad/nove-the-italian-kitchen-banjara-hills" alt="" title>Delivery Menu</a></li>
            {/* <li className="icon-rep"><a href="#" alt="" title>Review us on google</a></li> */}
            <li className="icon-rep"><a href="https://www.instagram.com/nove_hyd/?hl=en" alt="" title>FOLLOW US ON INSTAGRAM!</a></li>
            {/* <li className="icon-rep"><a href="#" alt="" title>Review us on google</a></li>
            <li className="icon-rep"><a href="#" alt="" title>Review us on google</a></li>
            <li className="icon-rep"><a href="#" alt="" title>Review us on google</a></li> */}
          </ul>
        </div>
        <p>Powered by <a href="https://veegam.com/" rel="nofollow">Veegam</a></p>
      </div>

</>
  )
}

export default Landing